import React, { FC } from 'react';
import { graphql } from 'gatsby';

import ArticlesList from '../components/articles-list/articles-list';
import AppContainer from '../components/app-container/app-container';
import { ArticlePages } from '../types/article';
import { Query } from '../types/query';

type BlogProps = Query<ArticlePages>;

const Blog: FC<BlogProps> = ({ data }: BlogProps) => {
  const {
    allMdx: {
      nodes: allArticles,
    },
  } = data;

  return (
    <AppContainer>
      <ArticlesList articles={allArticles} />
    </AppContainer>
  );
};

export const query = graphql`
  {
    allMdx(
      sort: {
        fields: [frontmatter___date]
        order: DESC
      }
    ) {
      nodes {
        fields {
          slug
        }
        frontmatter {
          title
          description
          tags
          date(formatString: "MMMM YYYY")
        }
      }
    }
  }
`;

export default Blog;
